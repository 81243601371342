<template>
  <div class="group-screen">
    <div class="g-week-switcher">
      <div class="edit">
        <input :value="count" v-on:change="e=>updCount(e)" min="1" max="150">
          <div>
          <div class="arrows" v-on:click="updCount(1)"><font-awesome-icon icon="caret-up" /></div>
          <div class="arrows" v-on:click="updCount(-1)"><font-awesome-icon icon="caret-down" /></div>
        </div>
      </div>
      <p class="input-subtitle none-selectable">Кол-во пациентов</p>

      <div class="edit">
        <input :value="weight" v-on:change="e=>updWeight(e)" min="10" max="150">
          <div>
          <div class="arrows" v-on:click="updWeight(1)"><font-awesome-icon icon="caret-up" /></div>
          <div class="arrows" v-on:click="updWeight(-1)"><font-awesome-icon icon="caret-down" /></div>
        </div>
      </div>
      <p class="input-subtitle none-selectable">Средний вес<br>пациента (кг)</p>
      
      <Btn v-for="w in weeks" :key="w.id" :name="'week'+w.num" :title="w.title" :active="w.active" v-on:click.native="weekCheck(w)"/>
    </div>

    <div class="results">
      <div class="chb-block" v-if="showProgSwitch"
            @mouseover="flOver = true" @mouseleave="flOver = false">
        <CheckBox  v-if="showProgSwitch" 
            :title="checkTitle" 
            :checked="programflag"
            v-on:click.native="flagCheck()" />
        &nbsp;<Footnote v-if="showProgSwitch" :remOver = "flOver"/>
      </div>
      <div class="datablock">
        <div class="tblblock">
        <Table :width='500' :data='drugs_table' :areaRemText='areaRemarkText'
          :remText='remarkText' :flRemark='remFlag' :flAreaRem='areaRemarkFlag'/>
        </div>
        <VerticalGraph :data='drugs_graph' :flag='programflag' :twoBars='showProgSwitch'/>
      </div>
    </div>
  </div>
</template>

<script>
import Table from '@/components/table/Table.vue'
import VerticalGraph from '@/components/graphic/VerticalGraph.vue'
import Btn from '@/components/Btn.vue'
import CheckBox from '@/components/CheckBox.vue'
import Footnote from '@/components/Footnote.vue'
import drugList from '@/views/drugStore';
import schemas from '@/views/schemas';
import dataStore from '@/views/data';

import { eventBus } from '@/main'

export default {
  components:{
    Table,
    VerticalGraph,
    Btn, CheckBox, Footnote
  },
  props:{
    week:Number,
    weight:Number,
    programflag:Boolean,
    count:Number,
  },
  data:function(){
    return{
      refFl:0,
      remFlag: true,
      flOver: false,
      areaRemarkFlag: false,
      areaRemarkText: '** Стоимость терапии рассчитывается с допущением, что у пациентов стандартная площадь тела, равная 1,79 м<sup>2</sup>',
      method: dataStore.currMethod,
      nivo_1mg: {name:'Ниволумаб', dose:'1 мг/кг', color:'#03417A'},
      ipili_3mg: {name:'Ипилимумаб', dose:'3 мг/кг', color:'#00C013'},
      nivo_480mg: {name:'Ниволумаб', dose:'480 мг', color:'#0084BD'}
    }
  },
  computed:{

    weeks: function() {

      let weeks = [];

      if((dataStore.currIndic==4 || dataStore.currIndic==5) && 
        this.programflag && dataStore.currWeeks!=24) {
        dataStore.currWeeks = 24;
        eventBus.$emit('week-change', 24);
      } 
      if((dataStore.currIndic==4 || dataStore.currIndic==5) && 
        this.programflag);// Не показываем 12 недель
      else {
        weeks.push({title:'12 недель', num:12, active:dataStore.currWeeks==12});
      }
      weeks.push({title:'24 недели', num:24, active:dataStore.currWeeks==24});
      if((dataStore.currIndic==4 || dataStore.currIndic==5) && this.programflag) return weeks;
      weeks.push({title:'36 недель', num:36, active:dataStore.currWeeks==36});
      weeks.push({title:'52 недели', num:52, active:dataStore.currWeeks==52});
      return weeks;
    },

    showProgSwitch: function() {
      let showFlag = false;

      if(dataStore.currIndic<2 || (dataStore.currIndic>3 && dataStore.currIndic<6)) showFlag = true;

      return showFlag;
    },

    checkTitle:function(){

      let title;

      // if(dataStore.currIndic<2) title = 'Участие в программе целевой передачи препарата Ипилимумаб (для терапии меланомы и ГЦК)'; 
      // if(dataStore.currIndic>3 && dataStore.currIndic<6) title = 'Участие в программе целевой передачи препарата Ипилимумаб (для терапии 1Л РЛ)';
      if(dataStore.currIndic<2) title = 'Участие в программе целевой передачи препарата Ипилимумаб'; 
      if(dataStore.currIndic>3 && dataStore.currIndic<6) title = 'Участие в программе целевой передачи препарата Ипилимумаб';

      return title;
    },

    drugs_table:function(){

      let tableData = [], tableLine = [], methResult = [], len, lineData = {};
      let methData = schemas[this.method], drug, name, dose, total = 0, ref;
      let header = ['', 'Стоимость одного курса, руб'];
      let tableFooter = [], singleSumm = 0, compensation = 0;

      ref = this.refFl;
      tableData.push(header);
      methResult = dataStore.calcMethodResults(this.method, this.programflag);
      len = methResult.length;
      if((dataStore.currIndic==4 || dataStore.currIndic==5) && this.programflag) { // схемы для РЛ
        this.remFlag = true;
        this.remarkText = '* С учетом выбытия пациентов с программы терапии';
        header.push('Общая стоимость, руб *');
        compensation = methResult[1].compensat;
      } else {
        this.remFlag = false;
        this.remarkText = '';
        header.push('Общая стоимость, руб');
      }
      for(let cnt=0 ; cnt<len ; cnt++) {
          drug = methData.prepData[cnt].prepNum[0];
          name = drugList[drug].name;
          dose = '' + methData.prepData[cnt].dose;
          if(methData.prepData[cnt].mode=='a') dose = dose + ' мг';
          if(methData.prepData[cnt].mode=='m') dose = dose + ' мг/кг';
          if(methData.prepData[cnt].mode=='s') {
            dose = dose + ' мг/м<sup>2 **</sup>';
            this.areaRemarkFlag = true;
          }
          lineData = {};
          lineData.name = name;
          lineData.dose = dose;
          lineData.color = methData.prepData[cnt].color;
          tableLine = [];
          tableLine.push(lineData);
          tableLine.push(dataStore.padDigits(methResult[cnt].cursPrice));
//          tableLine.push(dataStore.padDigits(methResult[cnt].cursPrice*this.count));
          tableLine.push(dataStore.padDigits(methResult[cnt].groupPrice));
          tableData.push(tableLine);
          singleSumm += methResult[cnt].cursPrice;
          total += methResult[cnt].groupPrice;
      }
      tableFooter.push({name:'Итого'});
      tableFooter.push(dataStore.padDigits(singleSumm));
      tableFooter.push(dataStore.padDigits(total));
      tableData.push(tableFooter);
      if(this.programflag && this.remFlag) {
        tableFooter = [];
        tableFooter.push({name:'Сумма возмещения'});
        tableFooter.push('');
        tableFooter.push(dataStore.padDigits(compensation));
        tableData.push(tableFooter);
      }
      return tableData;

      // return [
      //   ['', 'Стоимость одного курса, руб', 'Общая стоимость, руб'],
      //   [this.nivo_1mg, '200000', '2000000'],
      //   [this.ipili_3mg, '100000', '1000000'],
      //   [this.nivo_480mg, '500000', '5000000'],
      //   [{name:'Итого'},'800000','8000000']
      // ]
    },
    drugs_graph:function(){

      let graphData = {}, ref;

      let len, barData = {}, origBar = [], optimBar = [], retData = {}, drugDesc = {};
      let methData = schemas[this.method], drug, name, methResult, optimResult, flOptim ;

      ref = this.refFl;
      flOptim = 0 ; methResult = dataStore.calcMethodResults(this.method, flOptim);
      flOptim = 1 ; optimResult = dataStore.calcMethodResults(this.method, flOptim);
      len = methResult.length;
      
      for(let cnt=0 ; cnt<len ; cnt++) {
          barData = {};
          drugDesc = {};
          drugDesc.color = methData.prepData[cnt].color;
          drugDesc.gcolor = methData.prepData[cnt].gcolor;//color scemes upd
          barData.drug = drugDesc;
          barData.cost = methResult[cnt].groupPrice;
          origBar.push(barData);
          barData = {};
          barData.drug = drugDesc;
          barData.cost = optimResult[cnt].groupPrice;
          optimBar.push(barData);
      }
      retData.origin = origBar;
      retData.optim = optimBar;
      return retData;

      // return {
      //   origin:[{drug:this.nivo_1mg, cost:10000000},{drug:this.ipili_3mg, cost:1000000},{drug:this.nivo_480mg, cost:5000000}],
      //   optim:[{drug:this.nivo_1mg, cost:2000000},{drug:this.ipili_3mg, cost:800000},{drug:this.nivo_480mg, cost:5000000}]
      // }
    },
  },
  methods:{
    updWeight:function(num){

      let new_weight, val;

      if(num==1 || num==-1) new_weight=this.weight+num;
      else {
        val = num.target.value;
        val = parseInt(val);
        if(isNaN(val)) new_weight = 75;
        else new_weight = val;
      }
      if(new_weight==null) new_weight = 75;
      if(new_weight>150) new_weight = 150;
      if(new_weight<30) new_weight = 30;
      dataStore.patWeight = new_weight;
      dataStore.calcMethodResults(this.method, this.programflag);
      this.refFl = !this.refFl;
      eventBus.$emit('weight-change', new_weight);
    },
    updCount:function(num){

      let new_count, val;

      if(num==1 || num==-1) new_count=this.count+num;
      else {
        val = num.target.value;
        val = parseInt(val);
        if(isNaN(val)) new_count = 1;
        else new_count = val;
      }
      if(new_count==null) new_count = 1;
      if(new_count>50) new_count = 50;
      if(new_count<1) new_count = 1;
      dataStore.totPats = new_count;
      dataStore.calcMethodResults(this.method, this.programflag);
      this.refFl = !this.refFl;
      eventBus.$emit('patnum-change', new_count);
    },
    weekCheck:function(w){
      for(let i=0; i<this.weeks.length; i++)
        this.weeks[i].active = false;
      w.active = true;
      eventBus.$emit('week-change', w.num);
      dataStore.currWeeks = w.num;
      dataStore.calcMethodResults(this.method, this.programflag);
      this.refFl = !this.refFl;
    },
    flagCheck:function(){
      eventBus.$emit('flag-change', !this.programflag);
    }
  },

  mounted(){
    for(let i=0; i<this.weeks.length; i++)
      if(this.weeks[i].num === this.week)
        this.weeks[i].active = true;
  }
}
</script>
<style>
.chb-block{
  display:flex;
  width: 590px;
}
.group-screen{
  display:flex;
  padding-top: 10px;
}
.results{
  width: 100%;
  padding-left: 5px;
}
.g-week-switcher{
  flex-shrink: 0;
  width: 180px;
  margin-right: 10px;
}
.edit{
  display:flex;
}
.edit input{
  width: 148px;
  line-height: 34px;
  background: transparent;
  border: 3px solid #787572;
  margin-bottom: 5px;
  text-align: center;
  color: #787572;
  font-size: 16px;
  font-weight: 600;
}
.arrows{
  cursor:pointer;
  background: #787572;
  color: #fff;
  padding: 0px 5px;
  margin-bottom: 2px;
  margin-left: 2px;
}
.input-subtitle{
  margin: 0 0px 14px 0;
  font-size: 14px;
  text-align: center;
  color:#787572;
}
.datablock{
  display:flex;
}
.tblblock{
  width: 534px;
  padding: 6px 5px 0 0;
}
</style>

